/* Footer */
.footer {
  position: relative;
  margin-top: 44px;
  padding: 96px 0 60px;
  overflow: hidden;
  //background: linear-gradient(90deg, $primary-color 0%, $secondary-color 167%);
  background: #272b32;

  .social {
    margin-bottom: 40px;

    .social__item {
      margin: 0 2px;
      font-size: 0;
    }

    .social__link {
      display: inline-block;
      width: 40px;
      height: 40px;
      line-height: 40px;
      font-size: 16px;
      border-radius: 50%;
      color: $primary-color;
      background: $white;
      transition: all .25s;
      will-change: transform;

      &:hover {
        transform: scale(0.9);
        color: $primary-color;

        &::after {
          content: none;
        }
      }

      &::after {
        content: none;
      }
    }
  }

  .footer_nav {
    text-align: center;

    .footer_nav__item {
      display: inline;
      margin: 0 24px;
      font-size: 0;
    }

    .footer_nav__link {
      font-size: 16px;
      font-weight: 600;
      color: $white;
    }
  }

  @media only screen and (max-width: $desktop) {
    padding: 80px 0 60px;
  }

  @media only screen and (max-width: $mobile) {
    margin-top: 30px;
     padding: 60px 0;

     .footer_nav {
      .footer_nav__item {
        margin: 0 12px;
      }
     }
  }
}

/* Copyright */
.copyright {
  margin-top: 20px;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: rgba($white, 0.7);

  p {
    margin: 0;
  }

  a {
    font-weight: 500;
    color: $white;
  }

  @media only screen and (max-width: $mobile) {
    font-size: 14px;
  }
}
