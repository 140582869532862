/* Header */
.c-header {
  height: auto;
  transition: $global-transition;
}

.c-header__inner {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 48px $base-spacing-unit 40px;
  min-height: 80px;

  @media only screen and (max-width: $desktop) {
    align-items: baseline;
  }

  @media only screen and (max-width: $mobile) {
    padding: 30px $base-spacing-unit;
  }
}

/* Logo */
.logo__link {
  font-family: $heading-font-family;
  font-size: 21px;
  line-height: 26px;
  letter-spacing: -1px;
  font-weight: 900;
  text-transform: uppercase;

  &:hover {
    color: $text-color;
  }
}

.logo__image {
  max-height: 50px;
}

/* Nav */
.main-nav {
  display: flex;
  align-items: center;
  width: 100%;
  margin-left: 100px;

  .social {
    margin-left: auto;

    .social__link {
      font-size: 18px;
      vertical-align: middle;
    }
  }

  @media only screen and (max-width: $desktop) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    display: block;
    margin: 0;
    opacity: 0;
    overflow: auto;
    background: $white;
    transition: all .25s ease;

    &.is-open {
      z-index: 999;
      opacity: 1;
      transition: all .25s ease;
    }

    .nav__list {
      width: 100%;
      max-width: 360px;
      padding-left: 40px;

      .nav__item {
        display: block;
        margin: 0;

        &:last-child {
          margin-right: 0;
        }

        .dropdown-menu {
          position: relative;
          left: 0;
          padding: 0;
          opacity: 1;
          visibility: visible;
          transform: scale(1);
          box-shadow: none;

          .nav__link {
            margin-left: 8px;
            margin-bottom: 16px;
            font-size: 15px;

            &::before {
              content: "– ";
            }

            &:last-child {
              margin-bottom: 24px;
            }
          }
        }

        .nav__link {
          display: block;
          padding: 0;
          margin-bottom: 24px;
          font-size: 18px;
          font-weight: 600;

          &:hover {
            background-color: inherit;
          }

          .arrow-down {
            display: none;
          }

          &.active-link,
          &.cc-active {
            color: $primary-color;

            &::after {
              content: none;
            }
          }
        }
      }
    }

    .social {
      margin-left: 28px;

      .social__list {
        justify-content: flex-start;
      }
    }
  }
}

.main-nav__box {
  display: flex;
  align-items: center;

  .nav__icon-close {
    position: absolute;
    top: 20px;
    right: 40px;
    display: none;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    font-size: 18px;
    border-radius: 50%;
    cursor: pointer;
    background: linear-gradient(90deg, $primary-color 0%, $secondary-color 167%);

    &:hover {
      .ion-md-close {
        transform: rotate(90deg);
      }
    }

    .ion-md-close {
      color: $white;
      transition: $global-transition;
      will-change: transform;
    }
  }

  .nav__title {
    display: none;
  }

  @media only screen and (max-width: $desktop) {
    display: block;
    margin: 24px 0;

    .nav__icon-close {
      display: flex;
    }

    .nav__title {
      display: block;
      padding: 0 0 24px 40px;
      margin-bottom: 24px;
      font-size: 32px;
      line-height: 1;
      font-weight: 900;
      color: $heading-font-color;
      border-bottom: 1px solid $border-color;
    }
  }

  @media only screen and (max-width: $mobile) {
    .nav__icon-close {
      right: 30px;
    }
  }
}

.nav__list {

  .nav__item {
    display: inline-block;
    margin: 0 24px;

    &:last-child {
      margin-right: 0;
    }

    .nav__link {
      position: relative;
      padding-bottom: 10px;
      font-size: 16px;
      line-height: 1;
      font-weight: 600;
      cursor: pointer;

      &:hover {
        color: $text-color;
      }

      &.active-link {

        &::after {
          content: "";
          position: absolute;
          left: 50%;
          bottom: -6px;
          transform: translateX(-50%);
          display: block;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: $primary-color;
        }
      }
    }

    &.dropdown {
      position: relative;

      .arrow-down {
        vertical-align: middle;
      }

      &:hover {
        .dropdown-menu {
          opacity: 1;
          visibility: visible;
          transform: scale(1);
        }
      }
    }
  }

  .dropdown-menu {
    position: absolute;
    top: calc(100% + 8px);
    left: -32px;
    transform: translateZ(0) scale(0.9);
    backface-visibility: hidden;
    z-index: 100;
    min-width: 150px;
    display: block;
    padding: 20px;
    opacity: 0;
    visibility: hidden;
    transition: all .25s ease;
    border-radius: 8px;
    box-shadow: 0px 20px 20px rgba($primary-color, 0.07);
    background-color: $white;

    .nav__link {
      display: flex;
      padding: 8px 12px;
      font-size: 15px;
      border-radius: 4px;
      will-change: transform;

      &:hover {
        background-color: rgba($primary-color, 0.1);
      }

      &.active-link,
      &.cc-active {
        color: $primary-color;

        &::after {
          content: none;
        }
      }
    }
  }
}

.nav-button {
  display: flex;
  align-items: center;
  font-size: 24px;
  color: $text-color;
  cursor: pointer;

  .nav__icon-menu {
    display: none;
  }

  @media only screen and (max-width: $desktop) {
    margin-left: auto;

    .nav__icon-menu {
      display: block;
    }
  }
}